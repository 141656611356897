import { useEffect, useMemo } from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useAuth } from '@nhost/react-auth';

import sideImage from "./side-image.png";
import SignupForm from './SignupForm';
import DividerWithText from 'components/DividerWithText';
import GoogleAuthButton from 'components/GoogleAuthButton';
import Logo from "components/Logo";
import Page from 'components/Page';

const Signup = () => {
  const { signedIn } = useAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isSigningUserUp = useMemo(() => !signedIn, []);

  useEffect(() => {
    if ( signedIn ) {
      if ( !isSigningUserUp ) {
        window.location.assign("/");
      }

      // Otherwise, don't redirect user so that analytics call can finish
    } else {
      window.analytics.page("Sign Up");
    }
  }, [ signedIn, isSigningUserUp ]);



  return (
    <Page maxW = "unset" p = "0">
      <Box
        maxW = "6xl"
        mx = "auto"
        py = {{ base: 5, md: 10 }}
        px = {{ base: 4, md: 10 }}
      >
        <SimpleGrid
          columns = {{ base: 1, lg: 2 }}
          spacing = "14"
        >
          <Box
            w = "full"
            maxW = "xl"
            mx = "auto"
          >
            <Box
              bg = {{ md: mode('white', 'gray.700') }}
              rounded = {{ md: 'lg' }}
              px = {{ base: 4, md: 12 }}
              py = {{ base: 4, md: 8 }}
              borderWidth = {{ md: '1px' }}
              borderColor = { mode('gray.200', 'transparent') }
              shadow = {{ md: mode('light.lg', 'dark.lg') }}
            >
              <Logo
                h = "16"
                mb = {{ base: 16, md: 10 }}
                mx = "auto"
              />
              <Box
                mb = "8"
                textAlign = {{ base: 'center', md: 'start' }}
              >
                <Heading 
                  size = "lg" 
                  mb = "2" 
                  fontWeight = "extrabold"
                >
                  Welcome to Zine
                </Heading>
                <Text
                  fontSize = "lg"
                  color = { mode('gray.600', 'gray.400') }
                  fontWeight = "medium"
                >
                  Enter your info to get started
                </Text>
              </Box>
              <GoogleAuthButton width = "full">Sign up with Google</GoogleAuthButton>
              <DividerWithText>or</DividerWithText>
              <SignupForm />
            </Box>

            <Text
              mt = "8"
              align = "center"
              fontWeight = "medium"
            >
              Already have an account?{' '}
              <Link
                href = "/login"
                color = "primary.500"
              >
                Log in to Zine
              </Link>
            </Text>
          </Box>

          <Flex
            direction = "column"
            py = "24"
            display = {{ base: 'none', lg: 'flex' }}
          >
            <Flex
              as = "blockquote"
              flex = "1"
            >
              <Stack direction = "column" alignItems = "center">
                <Text
                  fontSize = "3xl"
                  textAlign = "center"
                >
                  Write with your audience. Get feedback. Publish.
                </Text>
                <Image
                  src = { sideImage }
                  alt = "Decorative image of connected lightbbulbs"
                  w = "48"
                />
                <Text>A powerful all-in-one solution for writers to crowdsource ideas and take pre-sales before launching </Text>
              </Stack>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Box>
    </Page>
  )
};

export default Signup;