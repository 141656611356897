import {
  Button,
  Icon,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { HiChatAlt } from "react-icons/hi";

const CommentButton = ({ totalComments, postUrl }) => (
  <Button
    leftIcon = { <Icon as = { HiChatAlt } w = { 5 } h = { 5 } /> }
    variant = "postIcon"
    onClick = { () => window.location.assign(postUrl) }
    isDisabled = { postUrl === window.location.pathname }
  >
    <Text 
      fontWeight = "medium"
      color = { mode("gray.900", "whiteAlpha.800") }
    >{ totalComments }</Text>
  </Button>
);

export default CommentButton;