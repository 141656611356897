import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Image,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";

import Card from "components/Card";
import book from "images/icons/book.svg";
import newspaper from "images/icons/newspaper.svg";

const Project = ({ project }) => (
  <Card>
    <VStack 
      spacing = "4"
      align = "flex-start"
    >
      <Box>
        <Heading 
          fontWeight = "medium" 
          fontSize = "xl"
        >{ project.title }</Heading>
        <Text>{ project.tagline }</Text>
      </Box>
      <Stack 
        direction = {{ base: "column", md: "row "}}
        gridColumnGap = {{ base: 0, md: 4 }}
        gridRowGap = {{ base: 4, md: 0 }}
        alignItems = {{ base: "unset", md: "center"}}
        width = "full"
      >
        <Image
          src = { project.cover_art_url || ( project.type === "book" ? book : newspaper )  }
          alt = "Project cover art"
          height = {{ base: "auto", md: "150px"}}
          width = {{ base: "full", md: "auto" }}
        />
        <Text 
          display = {{ base: "none", md: "inline-flex" }}
        >{ project.summary }</Text>
          <Accordion 
            display = {{ base: "inline-flex", md: "none" }}
            allowToggle
          >
            <AccordionItem width = "full">
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Summary
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              { project.summary }
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </VStack>
  </Card>
);

export default Project;