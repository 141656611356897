import { useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import ProjectForm from "./ProjectForm";
import Page from "components/Page";

const CreateProject = () => {
  useEffect(() => {
    window.analytics.page("Create Project");
  }, []);
  
  return (
    <Page>
      <Box
        maxW = "4xl"
        mx = "auto"
        my = "4"
      >
        <Heading fontSize = "2xl">Create Project</Heading>
        <Text mb = "4">Not sure yet? You can update any of the details below later on.</Text>
        <Box 
          bg = { mode('white', 'gray.700') }
          shadow = { mode('light.sm', 'dark.sm') }
          py = {{ base: 5, md: 10 }}
          px = {{ base: 4, md: 10 }}
          rounded = "md"
        >
          <ProjectForm />
        </Box>
      </Box>
    </Page>
  )
};

export default CreateProject;