import {
  Heading,
  Stack,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

const HeadingGroup = ({ title, description, ...props }) => (
  <Stack
    spacing = "1"
    { ...props }
  >
    <Heading
      size = "md"
      fontWeight = "semibold"
    >
      { title }
    </Heading>
    <Text
      color = { mode("gray.600", "gray.400") }
    >
      { description }
    </Text>
  </Stack>
);

export default HeadingGroup;