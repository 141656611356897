import { useEffect } from "react";
import { 
  Box,
  Stack
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Profile from "./Profile";
import Page from "components/Page";
import { auth } from "utils/nhost";

const GET_MYSELF = gql`
  query GetMyself($user_id: uuid!) {
    user: users_by_pk(id: $user_id ) {
      id
      avatar_url
      display_name
      bio
    }
  }
`;

const Settings = () => {
  const userId = auth.user().id;
  const { data } = useQuery(GET_MYSELF, {
    variables: {
      user_id: userId
    },
    context: {
      headers: {
        "x-hasura-role": "me"
      }
    }
  });

  useEffect(() => {
    window.analytics.page("User Settings");
  }, []);

  const user = data?.user;

  return (
    <Page>
      <Box maxW="3xl" mx="auto">
        { user ? (
          <Stack spacing="12">
            <Profile user = { user } />
          </Stack>
        ) : null }
      </Box>
    </Page>
  )
};

export default Settings;