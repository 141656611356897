import { useEffect, useState } from "react";
import {
  Button,
  chakra,
  IconButton,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  useDisclosure
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import gql from "graphql-tag";
import { useMutation } from '@apollo/client';

const UPDATE_PROJECT_POST = gql`
  mutation UpdateProjectPost($project_post_id: uuid!, $title: String!, $content: String!) {
    update_project_posts_by_pk(
      pk_columns: {id: $project_post_id}, 
      _set: {
        title: $title, 
        content: $content
      }) {
      id
    }
  }
`;

const UpdatePost = ({ post, onUpdateCallback }) => {
  const [ title, setTitle ] = useState(post.title);
  const [ content, setContent ] = useState(post.content);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ updateProjectPost ] = useMutation(UPDATE_PROJECT_POST, {
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  });

  useEffect(() => {
    setTitle(post.title);
    setContent(post.content);
  }, [ post ]);

  const onUpdate = e => {
    e.preventDefault();

    updateProjectPost({
      variables: {
        project_post_id: post.id,
        title,
        content
      }
    })
    .then(() => {
      window.analytics.track("Project Post Updated", {
        type: post.type
      });
      onUpdateCallback();
      onClose();
    })
  }

  return (
    <>
      <IconButton 
        aria-label="Edit post" 
        icon = { <EditIcon /> } 
        onClick = { onOpen }
        variant = "ghost"
        size = "sm"
      />

      <Modal isOpen = { isOpen } onClose = { onClose }>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Post</ModalHeader>
          <ModalCloseButton />
          <chakra.form onSubmit = { onUpdate }>
            <ModalBody experimental_spaceY = "1">
              <Input
                value = { title }
                onChange = { e => setTitle(e.target.value) }
              />
              <Textarea
                value = { content }
                onChange = { e => setContent(e.target.value) }
              />
            </ModalBody>
  
            <ModalFooter>
              <Button 
                colorScheme = "primary"
                type = "submit"
                isDisabled = { title.length === 0 || content.length === 0}
              >Save</Button>
            </ModalFooter>
          </chakra.form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdatePost;