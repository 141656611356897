import {
  Box,
  Container,
  useColorModeValue as mode
} from "@chakra-ui/react";

import Navigation from "./Navigation";

const Page = ({ ...props }) => (
  <Box
    minH = "100vh"
    display = "flex"
    flexDirection = "column"
    justifyContent = "stretch"
  >
    <Navigation />
    <Box pt = "4" flexGrow = "1" bg = { mode('gray.50', 'gray.800')}>
      <Container maxW = "unset" { ...props } display = "flex" flexDir = "column">
        { props.children }
      </Container>
    </Box>
  </Box>
);

export default Page;