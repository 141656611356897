const Container = {
  variants: {
    card: props => ({
      bg: props.colorMode === "light" ? "white" : "gray.700",
      shadow: props.colorMode === "light" ? "light.sm" : "dark.sm",
      m: 0,
      maxW: "unset",
      rounded: {
        sm: "md"
      }
    })
  }
};

export default Container;