import {
  FormControl,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react'
import * as React from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi';

const PasswordField = ({ id, name, autoComplete, value, onChange, errorMessage, isInvalid, helpText, label = "Password", ...props }) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);
  

  const onClickReveal = () => {
    onToggle()
    const input = inputRef.current
    if (input) {
      input.focus({ preventScroll: true })
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
          input.setSelectionRange(length, length)
      })
    }
  };

  return (
    <FormControl id = { id } isInvalid = { isInvalid }>
      <FormLabel>{ label }</FormLabel>
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={ onClickReveal }
            _focus={{
                boxShadow: "none",
            }}
            tabIndex = "-1"
          />
        </InputRightElement>
        <Input
          id = { id }
          name = { name }
          autoComplete = { autoComplete }
          type = {isOpen ? 'text' : 'password'}
          value = { value }
          onChange = { onChange }
          rounded = "sm"
          {...props}
        />
      </InputGroup>
      { helpText ? <FormHelperText>{ helpText }</FormHelperText> : null }
      <FormErrorMessage>{ errorMessage }</FormErrorMessage>
    </FormControl>
  )
};

export default PasswordField;