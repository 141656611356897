import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import components from "./components";
import config from "./config";
import shadows from "./shadows";
import styles from "./styles";

const theme = extendTheme({ colors, components, config, styles, shadows });

export default theme;