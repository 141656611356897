import { useEffect } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import EditorComponent from "./_Editor";

const GET_SECTION = gql`
  query GetSection($section_id: uuid!) {
    section: sections_by_pk(id: $section_id) {
      project {
        id
        slug
      }
      id
      content
      title
      visibility
      status
      index
    }
  }
`;

const Editor = () => {
  const { projectSlug, sectionId } = useParams();
  const { data } = useQuery(GET_SECTION, {
    variables: {
      section_id: sectionId
    },
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  });

  useEffect(() => {
    if ( data ) {
      if ( !data.section || data.section.project.slug !== projectSlug ) {
        window.location.replace(`/projects/${projectSlug}/write`)
      }
    }
  }, [ data, projectSlug ]);

  const section = data?.section;
  const isReady = section && section.project.slug === projectSlug;

  useEffect(() => {
    if ( isReady ) {
      window.analytics.page("Editor");
    }
  }, [ isReady ]);


  return isReady ? <EditorComponent section = { section } /> : null
};

export default Editor;