import { useEffect } from "react";
import {
  Box,
  Grid,
  Heading,
  Link,
  Stack
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import Author from "./Author";
import FollowProject from "./FollowProject";
import Project from "./Project";
import Tags from "./Tags";
import ProjectPosts from "components/ProjectsPosts";
import ShareToTwitterButton from "components/ShareToTwitterButton";

const ProjectProfile = ({ project, projectRole }) => {
  useEffect(() => {
    window.analytics.page("Project Profile", {
      role: projectRole,
      profile_visibility: project.profile_visibility
    });
    // eslint-disable-next-line 
  }, []);

  return (
    <Box px = "4">
      <Link 
        display = { projectRole === "author" ? "flex" : "none" }
        alignItems = "center"
        href = {`/projects/${project.slug}/dashboard`}
      >
        <ArrowBackIcon mx="3px" />
        Dashboard
      </Link>
      <Stack
        direction = {{ base: "column", md: "row" }}
        justifyContent = {{ base: "unset", md: "space-between" }}
        spacing = {{ base: 2, md: 0 }}
        alignItems = {{ base: "unset", md: "center"}}
        my = "8"
      >
        <Heading fontSize = "2xl">{ project.title }</Heading>
        <Stack 
          direction = {{ base: "column", md: "row" }}
          justifyContent = {{ base: "unset", md: "space-between" }}
          spacing = "2"
          alignItems = {{ base: "unset", md: "center"}}
        >
          <ShareToTwitterButton 
            ctaText = {`I'm following ${project.title}. You should check it out!`}
            slug = { project.slug }
          />
          <FollowProject project = { project } />
        </Stack>
      </Stack>
      <Grid
        templateColumns = {{ base: "none", md: "70% 30%" }}
        templateRows = {{ base: "none", md: "none" }}
        columnGap = {{ base: 0, md: 4 }} 
        rowGap = {{ base: 4, md: 0 }} 
      >
        <Project project = { project } />
        <Author author = { project.author } />
      </Grid>
      <Tags tags = { project.tags } />
      <ProjectPosts
        projectId = { project.id }
        role = { projectRole }
      />
    </Box>
  )
};

export default ProjectProfile;