import { 
  Circle,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  Stack, 
  Text, 
  useColorModeValue as mode
} from '@chakra-ui/react';
import moment from "moment-timezone";

import CommentButton from "./CommentButton";
import DeletePost from "./DeletePost";
import EditPost from "./EditPost";
import LikeButton from "./LikeButton";
import alert from "images/icons/alert.svg";
import question from "images/icons/question.svg";
import trophy from "images/icons/trophy.svg";
import { auth } from "utils/nhost";

const postTypeIcons = {
  update: alert,
  milestone: trophy,
  question: question
}

const ProjectPost = ({ post, refetchData, onDelete }) => {
  const postUrl = `/projects/${post.project.slug}/posts/${post.id}`;
  const isAuthor = post.project.author.id === auth.user()?.id;

  return (
    <>
      <Container variant = "card" width = "full" py = "4">
        <Stack spacing = "4" flex = "1">
          <Flex direction = "column">
            <HStack justifyContent = "space-between">
              <Heading fontSize = "md" fontWeight = "semibold">
                { postUrl === window.location.pathname ? (
                  <HStack>
                    <Circle
                      size = "12"
                      bg = { mode("white", "gray.800" )}
                    >
                      <Image src = { postTypeIcons[post.type] } boxSize="6" />
                    </Circle>
                    <Text>{ post.title }</Text>
                  </HStack>
                ) : (
                  <Link href = { postUrl } >{ post.title }</Link>
                )}
              </Heading>
              <HStack>
                { isAuthor ? <DeletePost onDeleteCallback = { onDelete || refetchData } post = { post } /> : null }
                { isAuthor ? <EditPost onUpdateCallback = { refetchData } post = { post } /> : null }
              </HStack>
            </HStack>
            <Text fontSize = "sm" color = { mode("gray.600", "gray.400") }>{ moment(post.created_at).format("LLL") }</Text>
          </Flex>
          <Flex>
            { post.content }
          </Flex>
          <Stack direction = "row" spacing = "2">
            <LikeButton 
              totalLikes = { post.total_likes.aggregate.count }
              isLiked = { post.likes.length > 0 }
              postId = { post.id }
            />
            <CommentButton
              totalComments = { post.total_comments.aggregate.count }
              postUrl = { postUrl }
            />
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

export default ProjectPost;