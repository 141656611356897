import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '@nhost/react-auth';

import { auth } from "utils/nhost";

const AuthGate = ({ shouldBeSignedIn, ignoreAuth, component: Component, ...rest }) => {
  const { signedIn } = useAuth();
  const { location } = rest;

  return (
    <Route { ...rest } render = { props => {
      if ( signedIn === null ) {
        return null;
      }

      if ( ignoreAuth ) {
        return <Component { ...props } />
      }

      if ( !signedIn && shouldBeSignedIn ) {
        return <Redirect to = {`/login?redirect=${location.pathname}`} />
      }
  
      // identify the user if entered app on an auth page
      if ( signedIn && shouldBeSignedIn && !document.referrer.startsWith(window.location.origin) ) {
        window.analytics.identify(auth.user().id);
      }

      return <Component { ...props } />
    }} />
  );
}

export default AuthGate;