import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  HStack,
  Link
} from "@chakra-ui/react";
import { DraftailEditor } from "draftail";
import createCounterPlugin from '@draft-js-plugins/counter';
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
import createSideToolbarPlugin from "draft-js-side-toolbar-plugin";
import { ArrowBackIcon } from "@chakra-ui/icons";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import readTime from 'draft-read-time'; 

import blockTypes from "./blockTypes";
import inlineStyles from "./inlineStyles";
import SectionName from "./SectionName";
import SectionDrawer from "./SectionDrawer";

import "draft-js/dist/Draft.css"
import "draftail/dist/draftail.css"
import "draft-js-inline-toolbar-plugin/lib/plugin.css";
import "draft-js-side-toolbar-plugin/lib/plugin.css";
import "./Editor.css";

const counterPlugin = createCounterPlugin();
const { CharCounter, WordCounter } = counterPlugin;

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const sideToolbarPlugin = createSideToolbarPlugin();
const { SideToolbar } = sideToolbarPlugin;
const plugins = [ inlineToolbarPlugin, sideToolbarPlugin, counterPlugin ];

const SAVE_CONTENT = gql`
  mutation SaveContent($section_id: uuid!, $content: String!) {
    update_sections_by_pk(
      pk_columns: { id: $section_id }
      _set: { content: $content }
    ) {
      id
      content
      __typename
    }
  }
`;

const Editor = ({ section }) => {
  const [ contentState, setContentState ] = useState(JSON.parse(section.content));
  const { projectSlug } = useParams();
  const [ updateContent ] = useMutation(SAVE_CONTENT);


  const onSaveContent = content => {
    const contentString = JSON.stringify(content);

    if ( contentString !== section.content ) {
      setContentState(content);
      updateContent({
        variables: {
          section_id: section.id,
          content: JSON.stringify(content)
        },
        context: {
          headers: {
            "x-hasura-role": "author"
          }
        }
      })
    }
  }

  const onBlur = hey => console.log(hey);

  const readTimeMin = readTime(contentState);

  return (
    <Container maxW = "container.lg" py = "4">
      <HStack 
        mb = "4"
        justifyContent = "space-between"
      >
        <Link 
          href = { `/projects/${projectSlug}/write` }
        >
          <ArrowBackIcon mx="2px" />
          All sections
        </Link>

        <SectionDrawer 
          section = { section } 
          CharCounter = { CharCounter }
          WordCounter = { WordCounter }
          readTimeMin = { readTimeMin }
          contentState = { contentState }
        />
      </HStack>
      <SectionName section = { section } />
      <Box className = "Editor" mt = "4">
        <DraftailEditor 
          placeholder = "Tell your story..." 
          plugins = { plugins }
          inlineStyles = { inlineStyles }
          blockTypes={ blockTypes }
          onSave = { onSaveContent }
          onBlur = { onBlur }
          rawContentState = { contentState }
          topToolbar = { null }
          stateSaveInterval = { 5000 }
          stripPastedStyles = { false }
        />
        <InlineToolbar />
        <SideToolbar />
      </Box>
    </Container>
  )
};

export default Editor;