import Select from "react-select";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const GET_TAGS = gql`
  query GetTags {
    tags(order_by: {name: asc}) {
      label: name
      value: id
    }
  }
`;

const TagsSelect = ({ value, onChange, limit, isMulti = true }) => {
  const { data, loading } = useQuery(GET_TAGS);

  return (
    <Select
      isLoading = { loading }
      options = { data?.tags }
      value = { value }
      onChange = { onChange }
      isMulti = { isMulti }
      closeMenuOnSelect = { false }
      isOptionDisabled = { () => !limit || ( value.length >= limit ) }
    />
  )
};

export default TagsSelect;