const colors = {
  primary: {
    50: "linear-gradient(0deg, #FFFFFF, #FFFFFF), #B57BFF",
    100: "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #B57BFF",
    200: "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #B57BFF",
    300: "linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #B57BFF",
    400: "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #B57BFF",
    500: "#B57BFF",
    600: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #B57BFF",
    700: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #B57BFF",
    800: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #B57BFF",
    900: "linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #B57BFF",
    950: "linear-gradient(0deg, #000000, #000000), #B57BFF"
  },
  danger: {
    100: "#FCE8EC",
    200: "#F9D0D9",
    300: "#F2A2B3",
    400: "#E95C7B",
    500: "#DF1642",
    600: "#9C0F2E",
    700: "#59091A",
    800: "#2D040D",
    900: "#160207"
  },
  Twitter: {
    100: "#94D3F9",
    200: "#72C5F7",
    300: "#53B8F5",
    400: "#37ACF3",
    500: "#1DA1F2",
    600: "#0D94E7",
    700: "#0C85D0",
    800: "#0B78BB",
    900: "#0A6CA8",
  }
};

export default colors;