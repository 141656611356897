import { useEffect } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import ProjectDashboard from "./ProjectDashboard";
import Page from "components/Page";
import ProjectNotFound from "components/ProjectNotFound";

const GET_PROJECT_DASHBOARD_DATA = gql`
  query GetProjectDashboardData($slug: String!) {
    projects(where: {slug: {_eq: $slug}}) {
      id
      type
      slug
      title
      recent_sections: sections(order_by: {updated_at: desc}, limit: 3) {
        title
        id
        updated_at
        __typename
      }
      sections_aggregate {
        aggregate {
          count
        }
      }
      project_followings_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const Index = () => {
  const { projectSlug } = useParams();
  
  const { data } = useQuery(GET_PROJECT_DASHBOARD_DATA, {
    variables: {
      slug: projectSlug
    },
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  });

  const project = data?.projects[0];

  useEffect(() => {
    window.analytics.page("Project Dashboard");
  }, []);

  return data ? (
    <Page maxW = "6xl">
      { project ? (
        <ProjectDashboard
          project = { project }
        />
      ) : <ProjectNotFound /> }
    </Page>
  ) : null
};

export default Index;