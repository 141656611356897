import {
  Box,
  Heading,
  HStack,
  Tag
} from "@chakra-ui/react";

const Tags = ({ tags }) => tags.length > 0 ? (
  <Box my = "4">
    <Heading mb = "2" fontSize = "xl" fontWeight = "normal">Tags</Heading>
    <HStack overflowWrap = "normal">
      { tags.map((tag, index) => <Tag key = { index }>{ tag.tag.name } </Tag>) }
    </HStack>
  </Box>
) : null;

export default Tags