import { useEffect } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import moment from "moment-timezone";

import CreateSection from "./CreateSection";
import Card from "components/Card";
import Page from "components/Page";
import ProjectNotFound from "components/ProjectNotFound";

const GET_PROJECT_SECTIONS = gql`
  query GetProjectSections($slug: String!) {
    projects(where: {slug: {_eq: $slug}}) {
      id
      type
      sections {
        title
        index
        id
        created_at
        updated_at
        __typename
      }
    }
  }
`;

const Sections = () => {
  const { projectSlug } = useParams();
  
  const { data } = useQuery(GET_PROJECT_SECTIONS, {
    variables: {
      slug: projectSlug
    },
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  });

  useEffect(() => {
    window.analytics.page("Sections");
  }, []);

  const project = data?.projects[0];

  return (
    <Page maxW = "4xl">
      { project ? (
        <Box>
          <Heading mb = "8" fontSize = "2xl" fontWeight = "medium">{ project.type === "book" ? "Chapters" : "Posts" }</Heading>
          <VStack py = "4" spacing = "2">
            <VStack spacing = "2" width = "full">
              { project.sections.slice().sort((s1, s2) => s1.index - s2.index).map(section => (
                <Card
                  as = "button"
                  width = "full"
                  rounded = "md"
                  py = "3"
                  alignItems = "center"
                  onClick = { () => window.location.assign(window.location.href + "/" + section.id) }
                  textAlign = "left"
                  key = { section.id }
                >
                  <Text
                    fontSize = "xl"
                  >{ section.title }</Text>
                  <Text fontSize = "sm">Created at: { moment(section.created_at).format("LLL") } | Updated at: { moment(section.updated_at).format("LLL") }</Text>
                </Card>
              ))}
            </VStack>
            <CreateSection 
              projectType = { project.type } 
              nextIndex = { project.sections.length } 
              projectId = { project.id }
            />
          </VStack>
        </Box>
      ) : <ProjectNotFound /> }
    </Page>
  )
};

export default Sections;