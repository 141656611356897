import { useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Heading } from "@chakra-ui/react";

import ProjectsList from "./ProjectsList";
import Page from "components/Page";

const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    projects(order_by: {created_at: desc}) {
      id
      author {
        display_name
        avatar_url
      }
      cover_art_url
      slug
      summary
      title
      type
      tagline
      project_tags {
        tag {
          name
        }
      }
    }
  }
`;

const Home = () => {
  const { data } = useQuery(GET_ALL_PROJECTS);
  const projects = data?.projects || [];

  useEffect(() => {
    window.analytics.page("Home");
  }, []);

  return (
    <Page maxW = "container.lg">
      <Heading my = "8" fontWeight = "normal">Public Projects on Zine</Heading>
      <ProjectsList projects = { projects } />
    </Page>
  )
};

export default Home;