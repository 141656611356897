import {
  Avatar,
  Heading,
  HStack,
  Text,
  VStack
} from "@chakra-ui/react";

import Card from "components/Card";
import writer from "images/icons/writer.svg";

const Author = ({ author }) => (
  <Card>
    <VStack 
      spacing = "4"
      align = "flex-start"
    >
      <Heading 
        fontWeight = "medium" 
        fontSize = "xl"
      >Author</Heading>
      <HStack spacing = "8" flexWrap = "wrap-reverse">
        <Avatar
          size = "2xl"
          src = { author.avatar_url || writer }
          alt = "Writer avatar"
        />
        <Text 
          fontWeight = "medium"
          fontSize = "xl"
        >{ author.display_name }</Text>
      </HStack>
      <Text>{ author.bio }</Text>
    </VStack>
  </Card>
);

export default Author;