import React from "react";
import { Stack } from '@chakra-ui/react'

const PostList = ({ children, ...props }) => {
  const items = React.useMemo(
    () => React.Children.toArray(children)
    .filter(React.isValidElement)
    .map((item, index, array) =>
      index + 1 === array.length ? React.cloneElement(item, {
        isLastItem: true,
      }) : item,
    ),
    [ children ]
  );
  
  return (
    <Stack as = "ul" { ...props }>
      { items }
    </Stack>
  );
};

export default PostList;
