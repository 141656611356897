import { BLOCK_TYPE } from "draftail";

const blockTypes = [
  { type: BLOCK_TYPE.HEADER_ONE },
  { type: BLOCK_TYPE.HEADER_TWO },
  { type: BLOCK_TYPE.HEADER_THREE },
  { type: BLOCK_TYPE.BLOCKQUOTE },
  { type: BLOCK_TYPE.CODE },
  { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
  { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
  { type: BLOCK_TYPE.UNSTYLED }
]

export default blockTypes;