import { useRef } from "react";
import { useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import Select from "react-select";
import { stateToMarkdown } from "draft-js-export-markdown";
import { EditorState, convertFromRaw } from 'draft-js'
// import { convertToHTML } from "draft-convert";

import FieldGroup from "components/FieldGroup";

const UPDATE_SECTION_VISIBILITY = gql`
  mutation UpdateSectionVisibility($section_id: uuid!, $visibility: String!) {
    update_sections_by_pk(
      pk_columns: { id: $section_id }
      _set: { visibility: $visibility }
    ) {
      id
      visibility
      __typename
    }
  }
`;

const UPDATE_SECTION_STATUS = gql`
  mutation UpdateSectionVisibility($section_id: uuid!, $status: String!) {
    update_sections_by_pk(
      pk_columns: { id: $section_id }
      _set: { status: $status }
    ) {
      id
      status
      __typename
    }
  }
`;

const DELETE_SECTION = gql`
  mutation DeleteSection($index: numeric!, $project_id: uuid!, $section_id: uuid!) {
    update_sections(
      where: { project_id: { _eq: $project_id }, index: {_gt: $index} },
      _inc: { index: -1 }
    ) {
      affected_rows
    }

    delete_sections_by_pk(id: $section_id) {
      id
    }
  }
`;

const DeleteSection = ({ section }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [ deleteSection ] = useMutation(DELETE_SECTION);
  const { projectSlug } = useParams();

  const onDeleteSection = () => {
    // Update the index of later sections
    deleteSection({
      variables: {
        index: section.index,
        project_id: section.project.id,
        section_id: section.id
      },
      context: {
        headers: {
          "x-hasura-role": "author"
        }
      }
    })
    .then(() => {
      window.analytics.track("Section Deleted");
      window.location.assign(`/projects/${projectSlug}/write`);
    })
  };

  return (
    <>
      <Button 
        colorScheme = "danger"
        width = "full"
        variant = "outline"
        onClick = { onOpen }
      >Delete</Button>

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete { section.title }
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref = { cancelRef } onClick = { onClose }>
                Cancel
              </Button>
              <Button colorScheme = "danger" onClick = { onDeleteSection } ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const SectionDrawer = ({ section, CharCounter, WordCounter, readTimeMin, contentState}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef();
  const [ updateSectionVisibility ] = useMutation(UPDATE_SECTION_VISIBILITY);
  const [ updateSectionStatus ] = useMutation(UPDATE_SECTION_STATUS);

  const onChangeVisibility = item => {
    if ( item.value !== section.visibility ) {
      updateSectionVisibility({
        variables: {
          section_id: section.id,
          visibility: item.value 
        },
        context: {
          headers: {
            "x-hasura-role": "author"
          }
        }
      })
      .then(() => {
        window.analytics.track("Section Updated", { field: "visibility" })
      })
    }
  }

  const exportMarkdown = () => {
    const state = EditorState.createWithContent(convertFromRaw(contentState));
    const markdown = stateToMarkdown(state.getCurrentContent());

    const data = new Blob([markdown], { type: 'text/plain'});
    const file = window.URL.createObjectURL(data);
    
    const a = document.createElement('a')
    a.href = file;
    a.setAttribute('download', `${section.title}.md`);
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  // const previewSection = () => {
  //   const state = EditorState.createWithContent(convertFromRaw(contentState));
  //   const html = `<h1>${section.title}</h1><br />` + convertToHTML(state.getCurrentContent());

  //   const data = new Blob([html], { type: 'text/html' });
  //   const file = window.URL.createObjectURL(data);
  //   window.open(file);
  // }

  const onChangeStatus = item => {
    if ( item.value !== section.status ) {
      updateSectionStatus({
        variables: {
          section_id: section.id,
          status: item.value 
        },
        context: {
          headers: {
            "x-hasura-role": "author"
          }
        }
      })
      .then(() => {
        window.analytics.track("Section Updated", { field: "status" })
      })
    }
  };

  const visibilityOptions = [
    { label: "Public (anyone can view)", value: "public" },
    { label: "Limited (only followers can view)", value: "limited" },
    { label: "Private (only you can view)", value: "private" }
  ];

  const statusOptions = [
    { label: "To Do", value: "to do" },
    { label: "In Progress", value: "in progress" },
    { label: "Reviewing", value: "reviewing" },
    { label: "Done", value: "done" }
  ];

  const visibilityValue = visibilityOptions.find(option => option.value === section.visibility);
  const statusValue = statusOptions.find(option => option.value === section.status);

  return (
    <>
      <IconButton 
        aria-label="Settings" 
        icon={<HamburgerIcon />} 
        ref = { btnRef }
        onClick = { onOpen }
        variant = "ghost"
      />
      <Drawer
        isOpen = { isOpen }
        placement = "right"
        onClose = { onClose }
        finalFocusRef = { btnRef }
      >
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader> </DrawerHeader>

          <DrawerBody>
            <FieldGroup title = "Settings">
              <VStack spacing = "2">
                <FormControl>
                  <FormLabel>Visibility</FormLabel>
                  <Select
                    options = { visibilityOptions }
                    value = { visibilityValue }
                    onChange = { onChangeVisibility }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    options = { statusOptions }
                    value = { statusValue }
                    onChange = { onChangeStatus }
                  />
                </FormControl>
              </VStack>
            </FieldGroup>

            <Divider my = "4" />

            <FieldGroup title = "Stats">
              <SimpleGrid spacing = "2" columnGap = "8" columns = "2">
                <Text>Read Time</Text>
                <Text>{ readTimeMin  || 0} min.</Text>

                <Text>Word Count</Text>
                <WordCounter />

                <Text>Character Count</Text>
                <CharCounter />
              </SimpleGrid>
            </FieldGroup>

            <Divider my = "4" />

            <FieldGroup title = "Export">
              <VStack spacing = "2">
                {/* <Button
                  onClick = { previewSection }
                  width = "full"
                >Preview</Button> */}

                <Button
                  onClick = { exportMarkdown }
                  width = "full"
                >Export to Markdown</Button>
              </VStack>
            </FieldGroup>
          </DrawerBody>
          <DrawerFooter>
            <DeleteSection section = { section } />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
};

export default SectionDrawer;