import { useState } from "react";
import { 
  Button, 
  chakra, 
  FormControl, 
  FormErrorMessage,
  FormLabel, 
  Input, 
  Stack,
  useBoolean
} from '@chakra-ui/react'
import PasswordField from "components/PasswordField";

import { auth } from "utils/nhost";
import parseAuthError from "utils/parseAuthError";

const SignupForm = () => {
  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ error, setError ] = useState({});
  const [ password, setPassword ] = useState("");
  const [ isLoading, { toggle: toggleIsLoading } ] = useBoolean(false);

  const isValid = name.length > 0 && email.length > 0 && password.length >= 8;

  const onSubmit = e => {
    e.preventDefault();
    setError({});
    toggleIsLoading();

    auth.register({
      email,
      password,
      options: { userData: { display_name: name, is_signup_complete: true }}
    })
    .then(resp => {
      const { user } = resp;
      
      window.analytics.alias(user.id, () => {
        window.analytics.identify(user.id, {
          createdAt: new Date().toISOString(),
          email,
          name
        }, () => {
          window.analytics.track("User Signed Up", {
            provider: "email"
          }, () => {
            window.location.assign("/");
          })
        });
      });
    })
    .catch(err => {
      toggleIsLoading();
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      });
    })
  };

  return (
    <chakra.form onSubmit = { onSubmit }>
      <Stack spacing="6">
        <FormControl id="name">
          <FormLabel>Name</FormLabel>
          <Input
            name="name" 
            type="text" 
            autoComplete="name" 
            value = { name }
            onChange = { e => setName(e.target.value) } 
            autoFocus = { true }
          />
        </FormControl>
        <FormControl id="email" isInvalid = { error.field === "email" }>
          <FormLabel>Email address</FormLabel>
          <Input 
            name="email" 
            type="email" 
            autoComplete="email" 
            value = { email }
            onChange = { e => setEmail(e.target.value) } 
          />
          <FormErrorMessage>{ error.message }</FormErrorMessage>
        </FormControl>
        <PasswordField 
          autoComplete = "new-password"
          helpText = "Password must be at least 8 characters long"
          value = { password }
          onChange = { e => setPassword(e.target.value) }
        />
        <Button 
          type="submit" 
          colorScheme="primary" 
          size="lg" 
          fontSize="md"
          loadingText = "Signing up..."
          isLoading = { isLoading }
          isDisabled = { !isValid }
        >
          Sign Up
        </Button>
      </Stack>
    </chakra.form>
  )
};

export default SignupForm;