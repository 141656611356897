import { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";

import { auth } from "utils/nhost";
import Segment from "utils/segment";

const segment = new Segment();

const GET_USER = gql`
  query GetUser($user_id: uuid!) {
    user: users_by_pk(id: $user_id ) {
      id
      account {
        email
      }
      display_name
      created_at
      is_signup_complete
      __typename
    }
  }
`;

const SIGNUP_USER = gql`
  mutation SignupUser($user_id: uuid!) {
    update_users_by_pk (
      pk_columns: { id: $user_id }
      _set: { is_signup_complete: true }
    ) {
      id
      is_signup_complete
      __typename
    }
  }
`;

const Auth = () => {
  const userId = auth.user().id;
  const { data: userData,  } = useQuery(GET_USER, {
    variables: {
      user_id: userId
    },
    context: {
      headers: {
        "x-hasura-role": "me"
      }
    }
  });
  const [ signupUser ] = useMutation(SIGNUP_USER);

  const isDataLoaded = !!userData;

  useEffect(() => {
    if ( isDataLoaded ) {
      const { is_signup_complete } = userData.user;
      if ( is_signup_complete ) {
        // User is logging in
        segment.identify(userId)
        .then(() => {
          segment.track("User Logged In", { provider: "google"})
          .then(() => window.location.assign("/"))
          .catch(() => window.location.assign("/"));
        })
        .catch(() => window.location.assign("/"));
      } else {
        // User just signed up
        signupUser({
          variables: {
            user_id: userId
          },
          context: {
            headers: {
              "x-hasura-role": "me"
            }
          }
        })
        .then(() => {
          segment.alias(userId)
          .then(() => {
            segment.identify(userId, {
              createdAt: userData.user.created_at,
              email: userData.user.account.email,
              name: userData.user.display_name
            })
            .then(() => {
              segment.analytics.track("User Signed Up", { provider: "google" })
            })
            .catch(() => window.location.assign("/"));
          })
          .catch(() => window.location.assign("/"));
        })
      }
    }
    // Not including userData as a dependency because cache updates causes effect to rerun
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isDataLoaded ]);

  return <div></div>;
};

export default Auth;