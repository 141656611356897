const Editable = {
  baseStyle: props => ({
    input: {
      rounded: "sm",
      _focus: {
        boxShadow: "none",
        borderBottom: "1px",
        borderColor: "primary.500"
      }
    },
    preview: {
      borderBottom: "1px",
      borderColor: props.colorMode === "light" ? "gray.200" : "whiteAlpha.300",
      width: "full"
    }
  })
};

export default Editable;