import { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import {
  Button,
  Icon,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { HiThumbUp } from "react-icons/hi";
import { useAuth } from '@nhost/react-auth';

import { auth } from "utils/nhost";

const LIKE_POST = gql`
  mutation TogglePostLike($post_id: uuid!) {
    insert_project_post_likes_one(object: { project_post_id: $post_id }) {
      created_at
    }
  }
`;

const UNLIKE_POST = gql`
  mutation UnlikePost($post_id: uuid!, $user_id: uuid!) {
    delete_project_post_likes_by_pk(project_post_id: $post_id, user_id: $user_id) {
      project_post_id
    }
  }
`;

const LikesButton = ({ totalLikes, isLiked, postId }) => {
  const [ userId, setUserId ] = useState(auth.user()?.id);
  const { signedIn } = useAuth();
  const [ _totalLikes, setTotalLikes ] = useState(totalLikes);
  const [ _isLiked, setIsLiked ] = useState(isLiked);
  const [ likePost ] = useMutation(LIKE_POST);
  const [ unlikePost ] = useMutation(UNLIKE_POST);

  useEffect(() => {
    setTotalLikes(totalLikes)
  }, [ totalLikes ]);

  useEffect(() => {
    setIsLiked(isLiked)
  }, [ isLiked ]);

  useEffect(() => {
    if ( !signedIn ) {
      setUserId(null);
    }
  }, [ signedIn ]);

  const toggleLike = () => {
    if ( _isLiked ) {
      unlikePost({
        variables: {
          post_id: postId,
          user_id: userId
        }
      })
      .then(() => {
        setIsLiked(false);
        setTotalLikes(prev => prev - 1);
        window.analytics.track("Project Post Unliked");
      })
    } else {
      likePost({
        variables: {
          post_id: postId
        }
      })
      .then(() => {
        setIsLiked(true);
        setTotalLikes(prev => prev + 1);
        window.analytics.track("Project Post Liked");
      })
    }
  }

  return (
    <Button
      leftIcon = { <Icon as={ HiThumbUp } w = { 5 } h = { 5 } color = { _isLiked ? "primary.500" : "unset" } /> }
      variant = "postIcon"
      isDisabled = { !userId }
      onClick = { toggleLike }
    >
      <Text 
        fontWeight = "medium"
        color = { mode("gray.900", "whiteAlpha.800") }
      >{ _totalLikes }</Text>
    </Button>
  )
};

export default LikesButton;