import { useState, useEffect } from "react";
import { useAuth } from '@nhost/react-auth';
import {
  Button
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";

import { auth } from "utils/nhost";

const GET_PROJECT_FOLLOW_STATUS = gql`
  query GetProjectFollowStatus($project_id: uuid!, $user_id: uuid!) {
    follow_status: project_followers_by_pk(project_id: $project_id, user_id: $user_id) {
      created_at
      project_id
      user_id
    }
  }
`;

const FOLLOW_PROJECT = gql`
  mutation FollowProject($project_id: uuid!) {
    insert_project_followers_one(object: {project_id: $project_id}) {
      project_id
    }
  }
`;

const UNFOLLOW_PROJECT = gql`
  mutation UnfollowProject($project_id: uuid!, $user_id: uuid!) {
    delete_project_followers_by_pk(project_id: $project_id, user_id: $user_id) {
      project_id
    }
  }
`;

const FollowProject = ({ project }) => {
  const [ userId, setUserId ] = useState(auth.user()?.id);
  const { signedIn } = useAuth();
  const [ isFollowing, setIsFollowing ] = useState(null);

  const { data } = useQuery(GET_PROJECT_FOLLOW_STATUS, {
    skip: !userId,
    variables: {
      project_id: project.id,
      user_id: userId
    }
  });

  const [ followProject ] = useMutation(FOLLOW_PROJECT, { variables: { project_id: project.id }});
  const [ unfollowProject ] = useMutation(UNFOLLOW_PROJECT, { variables: { project_id: project.id, user_id: userId }});

  useEffect(() => {
    if ( data ) {
      setIsFollowing(!!data.follow_status)
    }
  }, [ data ]);

  useEffect(() => {
    if ( !signedIn ) {
      setUserId(null);
    }
  }, [ signedIn ]);

  const toggleFollowing = () => {
    if ( isFollowing ) {
      unfollowProject()
      .then(() => {
        window.analytics.track("Project Unfollowed");
        setIsFollowing(false);
      });
    } else {
      followProject()
      .then(() => {
        window.analytics.track("Project Followed");
        setIsFollowing(true);
      });
    }
  }

  if ( !userId ) {
    return (
      <Button 
        onClick = { () => window.location.assign(`/login?redirect=${window.location.pathname}`) }
        colorScheme = "primary"
      >
        Log in to Follow
      </Button>
    )
  } else if ( userId === project.author.id ) {
    // User is author
    return null;
  } else {
    return (
      <Button
        visibility = { isFollowing === null ? "hidden" : "visible" }
        variant = { isFollowing ? "outline" : "solid" }
        colorScheme = "primary"
        onClick = { toggleFollowing }
      >
        { isFollowing ? "Unfollow Project" : "Follow Project"}
      </Button>
    )
  }
};

export default FollowProject;