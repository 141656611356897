const Button = {
  baseStyle: {
    _focus: {
      boxShadow: "none"
    }
  },
  variants: {
    postIcon: props => ({
      display: "inline-flex",
      experimental_spaceX: "0.25",
      color: "gray.400",
      _hover: {
        color: "gray.500"
      },
      _focus: {
        boxShadow: "none"
      },
      _disabled: {
        cursor: "default",
        opacity: "1",
        _hover: {
          color: "gray.400"
        }
      }
    })
  }
};

export default Button;