import {
  Box,
  Container,
  Heading,
  StackDivider,
  Text,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";

import AddComment from "./AddComment";
import UserAvatar from "components/UserAvatar";

const Comment = ({ comment }) => {
  return (
    <Box display = "flex" experimental_spaceX = "3" width = "full">
      <Box flexShrink = "0">
        <UserAvatar name = { comment.author.display_name } src = { comment.author.avatar_url } size = "md" />
      </Box>
      <Box fontSize = "sm">
        <Text fontWeight = "medium">{ comment.author.display_name }</Text>
        <Text opacity = "0.7">{ moment(comment.created_at).format("LLL") }</Text>
        <Text mt = "1">{ comment.content }</Text>
      </Box>
    </Box>
  )
}

const Comments = ({ post, onCreateComment }) => {

  return (
    <Container variant = "card" px = {{ base: "4", sm: "6" }}>
      <Heading py = "5" variant = "h2">Comments</Heading>

      <VStack
        divider = { <StackDivider borderColor="gray.200" /> }
        spacing = "4"
      >
        <AddComment 
          postId = { post.id }
          onCreateComment = { onCreateComment }
        />
        <VStack spacing = "8" width = "full" pb = "4">
          { post.comments.map(comment => <Comment key = { comment.id } comment = { comment } /> )}  
        </VStack>
      </VStack>
    </Container>
  )
};

export default Comments;