import { useEffect } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import ProjectPost from "./ProjectPost";
import { auth } from 'utils/nhost';

const GET_PROJECT_POST = gql`
  query GetProjectPost($project_post_id: uuid!, $user_id: uuid) {
    post: project_posts_by_pk(id: $project_post_id) {
      project {
        id
        slug
        title
        cover_art_url
        type
        author {
          display_name
          avatar_url
          id
        }
        post_visibility
      }
      id
      created_at
      title
      content
      type
      likes(where: {user_id: {_eq: $user_id }}) {
        created_at
      }
      total_likes: likes_aggregate {
        aggregate {
          count
        }
      }
      total_comments: comments_aggregate {
        aggregate {
          count
        }
      }
      comments(order_by: { created_at: desc }) {
        id
        author {
          id
          avatar_url
          display_name
        }
        content
        created_at
      }
    }
  }
`;

const DataFetch = () => {
  const userId = auth.user()?.id;
  const { projectSlug, projectPostId } = useParams();
  const { data, refetch } = useQuery(GET_PROJECT_POST, {
    variables: {
      project_post_id: projectPostId,
      user_id: userId
    }
  });

  useEffect(() => {
    if ( data ) {
      if ( !data.post || data.post.project.slug !== projectSlug ) {
        window.location.replace(`/projects/${projectSlug}`)
      }
    }
  }, [ data, projectSlug ]);

  const post = data?.post;

  return post ? <ProjectPost post = { post } refetchData = { refetch } /> : null
};

export default DataFetch;