import { useState } from "react";
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import writePaper from "images/icons/write-paper.svg";

const CREATE_SECTION = gql`
  mutation CreateSection($title: String!, $index: numeric!, $project_id: uuid!) {
    insert_sections_one(
      object: {
        title: $title,
        index: $index,
        project_id: $project_id
      }
    ) {
      id
      title
      index
      project_id
      created_at
      updated_at
      __typename
    }
  }
`;

const CreateSection = ({ projectType, nextIndex, projectId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ title, setTitle ] = useState("");
  const [ createSection, { loading } ] = useMutation(CREATE_SECTION);

  const sectionText = projectType === "book" ? "Chapter" : "Post";

  const onSubmit = e => {
    e.preventDefault();

    createSection({
      variables: {
        title,
        index: nextIndex,
        project_id: projectId
      },
      context: {
        headers: {
          "x-hasura-role": "author"
        }
      }
    })
    .then(() => {
      window.analytics.track("Section Created");
      onClose();
    })
  }

  return (
    <>
      <Box 
        as = "button"
        display = "flex"
        width = "full"
        justifyContent = "center"
        border = "dotted"
        borderColor = "gray.400"
        rounded = "md"
        py = "2"
        alignItems = "center"
        onClick = { onOpen }
      >
        <Text
          fontSize = "2xl"
          mr = "4"
        >Create { sectionText }</Text>
        <Image 
          src = { writePaper } 
          alt = "Write paper" 
          height = "50px"
        />
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create { sectionText }</ModalHeader>
          <ModalCloseButton />
          <chakra.form onSubmit = { onSubmit }>
            <ModalBody>
              <FormControl>
                <FormLabel>{ sectionText } Title</FormLabel>
                <Input
                  title = { title }
                  onChange = { e => setTitle(e.target.value) }
                  autoFocus
                />
                <FormHelperText>This can be changed later</FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button 
                colorScheme = "primary"
                type = "submit"
                isDisabled = { !title }
                isLoading = { loading }
              >Create { sectionText }</Button>
            </ModalFooter>
          </chakra.form>
        </ModalContent>
      </Modal>
    </>
  )
};

export default CreateSection;