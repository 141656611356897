import { useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import NoProjects from "./NoProjects";
import ProjectsList from "./ProjectsList";
import Page from "components/Page";
import { auth } from "utils/nhost";

const GET_ALL_AUTHOR_PROJECTS = gql`
  query GetAllAuthorProjects($user_id: uuid!) {
    projects(where: { created_by: { _eq: $user_id }}, order_by: { title: asc }) {
      id
      title
      type
      cover_art_url
      slug
      created_at
      followers_count: project_followings_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const UserProjects = () => {
  const { data } = useQuery(GET_ALL_AUTHOR_PROJECTS, {
    variables: {
      user_id: auth.user().id
    }, 
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  });

  useEffect(() => {
    window.analytics.page("My Projects");
  }, []);

  const projects = data?.projects;

  return (
    <Page>
      <Box
        maxW = "4xl"
        mx = "auto"
      >
        <Heading fontSize = "2xl" my = "4" >My Projects</Heading>
        { projects?.length === 0 ? <NoProjects /> : <ProjectsList projects = { projects || [] } /> }
      </Box>
    </Page>
  )
};

export default UserProjects;

