import { useEffect } from "react";
import { 
  Box, 
  Button,
  Container,
  Heading,
  Stack,
  StackDivider,
  Text,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";

import CreatePost from "./CreatePost";
import Stats from "./Stats";
import ProjectPosts from "components/ProjectsPosts";

const ProjectDashboard = ({ project }) => {

  useEffect(() => {
    window.analytics.page("Project Dashboard");
  }, []);

  return (
    <Box maxW = "6xl">
      <Box>
        <Heading variant = "h1">{ project.title }</Heading>
        <Stack 
          direction = {{ base: "column-reverse", md: "row" }}
          alignItems = "flex-start" 
          spacing = {{ base: 4, md: 6 }}
          mt = "4"
        >
          <VStack width = {{ base: "100%", md: "70%"}} spacing = "4">
            <Stats
              sectionLabel = { project.type === "book" ? "Chapters" : "Posts" } 
              sectionsCount = { project.sections_aggregate.aggregate.count }
              followersCount = { project.project_followings_aggregate.aggregate.count }
            />
            <CreatePost projectId = { project.id } />
            <ProjectPosts
              projectId = { project.id }
              role = "author"
            />
          </VStack>
          <Box width = {{ base: "100%", md: "30%"}} position = {{ base: "unset", md: "sticky" }} top = "4">
            <VStack spacing = {{ base: 2, md: 4, lg: 6 }}>
              <Container py = "4" variant = "card">
                <Button width = "full" as = "a" variant = "outline" href = {`/projects/${project.slug}`}>View Public Profile Page</Button>
              </Container>

              <Container py = "4" variant = "card">
                <Heading as = "h3" variant = "h3">Recent Work</Heading>
                <VStack divider = {<StackDivider /> }>
                  { project.recent_sections.map(section => (
                    <Box 
                      as = "a" 
                      width = "full" 
                      py = "4"
                      href = { `/projects/${project.slug}/write/${section.id}`}
                      key = { section.id }
                    >
                      <Text>{ section.title }</Text>
                      <Text fontSize = "sm" opacity = "0.7">{ moment(section.updated_at).format("LLL") }</Text>
                    </Box>
                  ))}
                </VStack>
                <Button 
                  mt = "2"
                  width = "full" 
                  variant = "outline" 
                  as = "a" 
                  href = {`/projects/${project.slug}/write`}
                  size = "sm"
                >{ project.recent_sections.length > 0 ? "View All" : "Start Writing" }</Button>
              </Container>
            </VStack>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
};

export default ProjectDashboard;