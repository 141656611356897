import {
  Avatar,
  Box,
  Container,
  SimpleGrid,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

import ProjectCoverArt from "components/ProjectCoverArt";

const Project = ({ project }) => (
  <Container 
    variant = "card"
    href = { `/projects/${project.slug}` }
    as = "a"
    display = "flex"
    flexDirection = "column"
    p = "6"
  >
    <Box flex = "1" display = "flex" flexDirection = "column">
      <Box height = "32" width = "full">
        <ProjectCoverArt
          src = { project.cover_art_url }
          projectType = { project.type }
          maxH = "32"
          maxW = "32"
          mx = "auto"
          height = "full"
        />
        <Avatar 
          src = { project.author.avatar_url } 
          name = { project.author.display_name }
          size = "sm"
          bottom = "30px"
          shadow = { mode("light.md", "dark.md") }
        />
      </Box>
      <Box overflow = "hidden" experimental_spaceY = "1" mt = "4">
        <Text fontWeight = "medium">{ project.title }</Text>
        <Text fontSize = "sm">{ project.tagline }</Text>
      </Box>
    </Box>
    <Box mt = "4">
      <Text fontSize = "sm" textTransform = "capitalize" opacity = "0.7">{ project.type }</Text>
    </Box>
  </Container>
);

const ProjectsList = ({ projects }) => (
  <SimpleGrid columns = {{ base: 1, sm: 2, md: 3, lg: 4 }} gap = "6">
    { projects.map(project => <Project key = { project.id } project = { project } /> )}
  </SimpleGrid>
);

export default ProjectsList;