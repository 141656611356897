import { useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  StackDivider,
  Textarea,
  useColorModeValue as mode
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import HeadingGroup from "./HeadingGroup";
import Card from "components/Card";
import FieldGroup from "components/FieldGroup";
import { storage } from "utils/nhost";

const UPDATE_USER_NAME_AND_BIO = gql`
  mutation UpdateUserNameAndBio($user_id: uuid!, $name: String!, $bio: String) {
    update_users_by_pk (
      pk_columns: { id: $user_id }
      _set: { display_name: $name, bio: $bio }
    ) {
      id
      display_name
      bio
      __typename
    }
  }
`;

const UPDATE_AVATAR_URL = gql`
  mutation UpdateAvatarUrl($user_id: uuid!, $avatar_url: String!) {
    update_users_by_pk (
      pk_columns: { id: $user_id }
      _set: { avatar_url: $avatar_url }
    ) {
      id
      avatar_url
      __typename
    }
  }
`;

const Profile = ({ user }) => {
  const [ name, setName ] = useState(user.display_name);
  const [ bio, setBio ] = useState(user.bio);
  const [ avatarUrl, setAvatarUrl ] = useState(user.avatar_url);
  const [ updateUserNameAndBio ] = useMutation(UPDATE_USER_NAME_AND_BIO);
  const [ updateAvatarUrl ] = useMutation(UPDATE_AVATAR_URL);
  const uploadButtonRef = useRef(null);

  const onSaveChanges = () => {
    if ( name !== user.display_name || bio !== user.bio ) {
      if ( name !== user.display_name ) {
        window.analytics.identify(user.id, { name });
      }

      updateUserNameAndBio({
        variables: {
          user_id: user.id,
          name,
          bio
        },
        context: {
          headers: {
            "x-hasura-role": "me"
          }
        }
      })
      .then(() => {
        window.analytics.track("User Profile Updated");
      })
    }
  }

  const onCancelChanges = () => {
    setName(user.display_name);
    setBio(user.bio);
  }

  const onAvatarChange = async e => {
    const oldAvatarPath = avatarUrl.split("storage/o")[1];

    const file = e.target.files[0]; 
    const newAvatarPath = `/public/profileImages/${user.id}/${file.name}`;

    await storage.put(`${newAvatarPath}?r=full`, file, null, data => {
      if ( data.loaded === data.total ) {
        const newAvatarUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/storage/o${newAvatarPath}`;
        updateAvatarUrl({
          variables: {
            user_id: user.id,
            avatar_url: newAvatarUrl
          },
          context: {
            headers: {
              "x-hasura-role": "me"
            }
          }
        }).then(() => storage.delete(oldAvatarPath).catch(() => null));
        setAvatarUrl(newAvatarUrl);
        window.analytics.track("User Avatar Updated");
      }
    })
    .catch(err => console.log(err));
  }

  const handleUploadButtonClick = () => {
    uploadButtonRef.current.click()
  }

  return (
    <Stack
      as = "section"
      spacing = "6"
    >
      <HeadingGroup
        title = "Profile"
        description = ""
      />  
      <Card>
        <Stack 
          divider = { <StackDivider /> }
          spacing = "6"
        >
          <FieldGroup
            title = "About You"
          >
            <SimpleGrid
              columns = {{ base: 1, sm: 2 }}
            >
              <Box mx = "auto" my = "auto">
                <Avatar 
                  src = { avatarUrl }
                  size = "2xl"
                />
                <IconButton 
                  aria-label = "Change avatar"
                  icon = { <EditIcon /> }
                  rounded = "full"
                  size = "sm"
                  bg = { mode("white", "gray.700")}
                  shadow = { mode("light.sm", "dark.sm")}
                  onClick = { handleUploadButtonClick }
                />
                <Input
                  type = "file"
                  onChange = { onAvatarChange }
                  display = "none"
                  ref = { uploadButtonRef }
                  accept="image/png, image/gif, image/jpeg"
                />
              </Box>
              <Stack spacing = "2">
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    value = { name }
                    onChange = { e => setName(e.target.value) }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Bio</FormLabel>
                  <Textarea
                    resize = "none"
                    value = { bio || "" }
                    onChange = { e => setBio(e.target.value) }
                    placeholder = "Tell us about yourself..."
                  />
                </FormControl>

                <HStack justify = "flex-end">
                  <Button
                    size = "sm"
                    onClick = { onCancelChanges }
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme = "primary"
                    size = "sm"
                    onClick = { onSaveChanges }
                    isDisabled = { name === user.display_name && bio === user.bio }
                  >
                    Save
                  </Button>
                </HStack>
              </Stack>
            </SimpleGrid>
          </FieldGroup>
        </Stack>
      </Card>
    </Stack>
  )
};

export default Profile;