import {
  Box,
  Button
} from "@chakra-ui/react";
import { FaTwitter } from "react-icons/fa"

const ShareToTwitterButton = ({ ctaText, slug }) => {
  const intentUrl = `https://twitter.com/intent/tweet?text=${ctaText}&url=${window.location.origin + "/" + slug}&hashtags=Zine,WriteInPublic`;
  const onClick = () => {
    window.analytics.track("Project Shared to Twitter");
    window.open(intentUrl);
  }
  return (
    <Button
      leftIcon = { <Box as = { FaTwitter } color = "white" /> }
      colorScheme = "Twitter"
      color = "white"
      onClick = { onClick }
    >
      Share Project on Twitter
    </Button>
  )
};

export default ShareToTwitterButton;