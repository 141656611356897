import {
  Heading,
  Image,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";

import Card from "components/Card";
import notFound from "images/icons/not-found.svg";

const NotFound = () => (
  <Card>
    <VStack 
      spacing = "8" 
      py = "10"
    >
      <Heading fontSize = "2xl">Project Not Found</Heading>
      <Image
        src = { notFound }
        alt = "Not Found"
      />
      <Text textAlign = "center">
        Looks like there might not be a project at this url. Why not <Link href = "/projects/new" color = "primary.500">make your own!</Link>
      </Text>
    </VStack>
  </Card>
);

export default NotFound;