import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useAuth } from "@nhost/react-auth";

import NotFound from "./NotFound";
import ProjectProfile from "./ProjectProfile";
import Page from "components/Page";
import { auth } from "utils/nhost";

const GET_PROJECT = gql`
  query GetProject($slug: String!, $user_id: uuid) {
    projects(where: {slug: {_eq: $slug}}) {
      id
      profile_visibility
      slug
      title
      post_visibility
      cover_art_url
      created_at
      type
      summary
      author {
        id
        avatar_url
        bio
        display_name
      }
      tags: project_tags {
        tag {
          name
        }
      }
      tagline
    }
  }
`;

const Index = () => {
  const [ projectRole, setProjectRole ] = useState("public");
  const { signedIn } = useAuth();

  const { projectSlug } = useParams();
  const userId = auth.user()?.id;
  const { data } = useQuery(GET_PROJECT, {
    variables: {
      slug: projectSlug,
      user_id: userId
    }
  });

  const project = data?.projects[0];

  useEffect(() => {
    if ( !signedIn ) {
      setProjectRole("public")
    } else {
      const userId = auth.user()?.id;
      if ( userId === project?.author.id ) {
        setProjectRole("author");
      } else {
        setProjectRole("user");
      }
    }
  }, [ signedIn, project ]);

  return data ? (
    <Page>
      { project ? (
        <ProjectProfile 
          project = { project } 
          projectRole = { projectRole } 
        />
      ) : <NotFound /> }
    </Page>
  ) : null
};

export default Index;