import {
  Image
} from "@chakra-ui/react";

import book from "images/icons/book.svg";
import newspaper from "images/icons/newspaper.svg";

const ProjectCoverArt = ({ projectType, src, ...props }) => (
  <Image
    src = { src || ( projectType === "book" ? book : newspaper ) }
    alt = "Project cover art"
    { ...props }
  />
);

export default ProjectCoverArt;