import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue as mode,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon
} from '@chakra-ui/icons';
import { useAuth } from "@nhost/react-auth";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import Logo from "components/Logo";
import { auth } from "utils/nhost";

const AUTH_LINKS = [
  { href: "/projects", label: "My Projects" },
  // { href: "/following", label: "Following" }
];

const UNAUTH_LINKS = [

];

const GET_USER = gql`
  query GetUser($user_id: uuid!) {
    user: users_by_pk(id: $user_id) {
      id
      avatar_url
      __typename
    }
  }
`;

const Navigation = () => {
  const { signedIn } = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  const authButtonSize = useBreakpointValue({ base: "sm", md: "md" });
  const { data: userData, loading: isLoadingUserData } = useQuery(GET_USER, {
    variables: {
      user_id: auth.user()?.id
    },
    skip: !signedIn
  });

  const onLogOut = () => {
    window.analytics.track("User Logged Out");
    auth.logout()
    .then(() => window.analytics.reset())
  }

  return (
    <Box
      bg = { mode("white", "gray.800") }
      px = {{ base: "2", sm: "4", md: "6", lg: "8", xl: "16" }}
      borderBottomWidth = "1px"
      borderColor = { mode('gray.200', 'transparent') }
      shadow = {{ md: mode('light.lg', 'dark.lg') }}
    >
      <Flex
        h = "16"
        alignItems = "center"
        justifyContent = "space-between"
      >
        <IconButton
          size = "md"
          icon = { isOpen ? <CloseIcon /> : <HamburgerIcon /> }
          aria-label = "Open Menu"
          display = {{base: signedIn ? "inline-flex" : "none", md: "none" }}
          onClick = { onToggle }
          bg = "transparent"
        />

        <HStack
          spacing = "8"
          alignItems = "center"
        >
          <Box 
            as = "a" 
            href = "/" 
            display = 'flex' 
            alignItems = "flex-end"
          >
            <Logo h = "10" w = "auto" mr = "4" />
            <Text 
              display = {{ base: "none", md: "inline-flex" }} 
              fontSize = "3xl"
            >Zine</Text>
          </Box>
          <HStack
            as = "nav"
            spacing = "4"
            display = {{ base: "none", md: "flex" }}
          >
            { signedIn ? AUTH_LINKS.map((link, index) => <NavLink key = { index } { ...link } />) : UNAUTH_LINKS.map((link, index) => <NavLink key = { index } { ...link } />) }
          </HStack>
        </HStack>

        { signedIn ? (
          <Flex alignItems = "center">
            <Menu>
              <MenuButton
                as = { Button }
                rounded = "full"
                variant = "link"
                cursor = "pointer"
                minW = "0"
                display = { isLoadingUserData ? "none" : "block" }
              >
                <Avatar
                  size = "sm"
                  src = { userData?.user.avatar_url }
                />
              </MenuButton>
              <MenuList>
                <MenuItem as = "a" href = "/settings">Settings</MenuItem>
                <MenuDivider />
                <MenuItem onClick = { onLogOut }>Log Out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        ) : (
          <Flex
            direction = "row"
          >
            <Button
              as = "a"
              href = "/login"
              variant = "link"
              mr = "6"
              size = { authButtonSize }
            >
              Log In
            </Button>

            <Button
              as = "a"
              href = "/signup"
              colorScheme = "primary"
              size = { authButtonSize }
            >
              Sign Up
            </Button>
          </Flex>
        )}
      </Flex>

      { isOpen ? (
        <Box 
          pb = "4"
          display = {{ md: "none" }}
        >
          <Stack
            as = "nav"
            spacing = "4"
          >
            { signedIn ? AUTH_LINKS.map((link, index) => <NavLink key = { index } { ...link } />) : UNAUTH_LINKS.map((link, index) => <NavLink key = { index } { ...link } />) }
          </Stack>
        </Box>
      ) : null }
    </Box>
  )
};

const NavLink = ({ label, href }) => (
  <Link
    px = "2"
    py = "1"
    rounded = "md"
    _hover = {{
      textDecoration: "none",
      bg: mode("gray.200", "gray.700")
    }}
    href = { href }
  >
    { label }
  </Link>
);

export default Navigation;