import { 
  Box, 
  Text
} from '@chakra-ui/react';
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import Post from "./Post";
import PostList from "./PostList";
import { auth } from 'utils/nhost';

const GET_PROJECT_POSTS = gql`
  query GetProjectPosts($project_id: uuid!, $user_id: uuid) {
    project: projects_by_pk(id: $project_id) {
      id
      post_visibility
      slug
      project_posts(order_by: { created_at: asc }) {
        id
        title
        type
        content
        created_at
        likes(where: {user_id: {_eq: $user_id }}) {
          created_at
        }
        project {
          slug
          author {
            id
          }
        }
        total_likes: likes_aggregate {
          aggregate {
            count
          }
        }
        total_comments: comments_aggregate {
          aggregate {
            count
          }
        }
        comments(limit: 3, order_by: { created_at: desc }) {
          id
          created_at
          author {
            id
            avatar_url
            display_name
          }
          content
        }
      }
    }
  }
`;

const ProjectPosts = ({ role, projectId }) => {
  const userId = auth.user()?.id;
  const { data, refetch } = useQuery(GET_PROJECT_POSTS, {
    variables: {
      project_id: projectId,
      user_id: userId
    },
    context: {
      headers: {
        "x-hasura-role": role
      }
    }
  });

  const project = data?.project;
  const posts = project?.project_posts || [];

  return (
    <Box 
      width = "full"
      as="section"
      py ={{
        base: '4',
        md: '8',
      }}
      display = { (!project || (project.post_visibility === "limited" && role === "public") || (project.post_visibility === "private" && role !== "author")) ? "none" : "block" }
    >  
      { posts.length === 0 ? (
        <Box width = "full">
          <Text textAlign = "center">No posts for this project yet!</Text>
        </Box>
      ) : (
        <PostList spacing="12">
          { posts.map(post => (
            <Post
              key = { post.id }
              post = { post }
              refetchData = { refetch }
            /> 
          ))}
        </PostList>
      )}
    </Box>
  )
};

export default ProjectPosts;