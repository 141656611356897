import { useState } from "react";
import { 
  Button, 
  chakra, 
  FormControl, 
  FormErrorMessage,
  FormLabel, 
  Input,
  Stack,
  useBoolean
} from '@chakra-ui/react'

import PasswordField from "components/PasswordField";
import { auth } from "utils/nhost";
import parseAuthError from "utils/parseAuthError";

const LoginForm = () => {
  const [ email, setEmail ] = useState("");
  const [ error, setError ] = useState({});
  const [ password, setPassword ] = useState("");
  const [ isLoading, { toggle: toggleIsLoading } ] = useBoolean(false);

  const isValid = email.length > 0 && password.length > 0;
  const redirect = new URLSearchParams(window.location.search).get("redirect");

  const onSubmit = e => {
    e.preventDefault();
    setError({});
    toggleIsLoading();

    auth.login({ email, password })
    .then(resp => {
      const { user } = resp;

      window.analytics.identify(user.id, {}, () => {
        window.analytics.track("User Logged In", {
          provider: "email"
        }, () => {
          window.location.assign(redirect || "/");
        })
      })
    })
    .catch(err => {
      toggleIsLoading();
      const parsedError = parseAuthError(err);
      setError(parsedError);

      window.analytics.track("Error Triggered", {
        error: parsedError.code
      });
    })

  };

  return (
    <chakra.form onSubmit = { onSubmit }>
      <Stack spacing="6">
        <FormControl 
          id="email" 
          isInvalid = { error.field === "email"}
        >
          <FormLabel>Email address</FormLabel>
          <Input 
            name="email" 
            type="email" 
            autoComplete="email" 
            value = { email }
            onChange = { e => setEmail(e.target.value) } 
          />
          <FormErrorMessage>{ error.message }</FormErrorMessage>
        </FormControl>
        <PasswordField 
          autoComplete = "current-password"
          isInvalid = { error.field === "current-password" }
          value = { password }
          onChange = { e => setPassword(e.target.value) }
          errorMessage = { error.message }
        />
        <Button 
          type="submit" 
          colorScheme="primary" 
          size="lg" 
          fontSize="md"
          loadingText = "Logging in..."
          isLoading = { isLoading }
          isDisabled = { !isValid }
        >
          Log in
        </Button>
      </Stack>
    </chakra.form>
  )
};

export default LoginForm;