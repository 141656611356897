import { 
  Circle,
  Flex,
  Image,
  Stack, 
  useColorModeValue as mode
} from '@chakra-ui/react';

import ProjectPost from "components/ProjectPost";
import alert from "images/icons/alert.svg";
import question from "images/icons/question.svg";
import trophy from "images/icons/trophy.svg";

const postTypeIcons = {
  update: alert,
  milestone: trophy,
  question: question
}

const Post = ({ post, isLastItem, refetchData, ...props }) => (
  <Stack
    as = "li"
    direction = "row"
    spacing = "4"
    { ...props }
  >
    <Flex
      direction = "column"
      alignItems = "center"
      aria-hidden = "true"
    >
      <Circle
        size = "12"
        bg = { mode("white", "gray.700" )}
        shadow = { mode("light.sm", "dark.sm") }
      >
        <Image src = { postTypeIcons[post.type] } boxSize="6" />
      </Circle>
      { !isLastItem && <Flex flex = "1" borderRightWidth = "1px" mb = "-12" /> }
    </Flex>
    <ProjectPost
      post = { post }
      refetchData = { refetchData }
      isFullPage = { false }
    />
  </Stack>
)

export default Post;