import Container from "./Container";
import Button from "./Button";
import Editable from "./Editable";
import Heading from "./Heading";
import Link from "./Link";

const components = {
  Container,
  Button,
  Editable,
  Heading,
  Link
};

export default components;