import { Avatar } from "@chakra-ui/react";

const UserAvatar = ({ name, src, ...props }) => (
  <Avatar
    name = { name }
    src = { src }
    { ...props }
  />
);

export default UserAvatar;