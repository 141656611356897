import {
    ChakraProvider,
    Box,
  } from '@chakra-ui/react';
  import {
    BrowserRouter as Router,
    Switch
  } from 'react-router-dom';
  
import theme from "./theme";
import AuthGate from "components/AuthGate";
import Auth from "pages/Auth";
import CreateProject from "pages/CreateProject";
import Home from "pages/Home";
import Login from "pages/Login";
import ProjectDashboard from 'pages/ProjectDashboard';
import ProjectPost from "pages/ProjectPost";
import ProjectProfile from "pages/ProjectProfile";
import UserProjects from "pages/UserProjects";
import Sections from "pages/Sections";
import Settings from "pages/Settings";
import Signup from "pages/Signup";
import Write from "pages/Editor";
  
function App() {
  return (
    <ChakraProvider theme = { theme }>
      <Box minH = "max" id = "App">
        
        <Router>
          <Switch>
            {/*
            <AuthGate shouldBeSignedIn path = "/projects/:projectSlug/settings" component = { ProjectSettings } />  */}
            <AuthGate exact path = "/" component = { Home } />
            <AuthGate shouldBeSignedIn path = "/auth" component = { Auth } />
            <AuthGate ignoreAuth path = "/login" component = { Login } />
            <AuthGate shouldBeSignedIn exact path = "/projects" component = { UserProjects } />
            <AuthGate shouldBeSignedIn path = "/projects/new" component = { CreateProject } />
            <AuthGate exact path = "/projects/:projectSlug" component = { ProjectProfile } />
            <AuthGate shouldBeSignedIn path = "/settings" component = { Settings } />
            <AuthGate ignoreAuth path = "/signup" component = { Signup } />
            <AuthGate shouldBeSignedIn path = "/projects/:projectSlug/dashboard" component = { ProjectDashboard } />
            <AuthGate path = "/projects/:projectSlug/posts/:projectPostId" component = { ProjectPost } />
            <AuthGate shouldBeSignedIn exact path = "/projects/:projectSlug/write" component = { Sections } />
            <AuthGate shouldBeSignedIn path = "/projects/:projectSlug/write/:sectionId" component = { Write } />
          </Switch>
        </Router>
      </Box>
    </ChakraProvider>
  );
}
  
export default App;