import {
  Heading,
  HStack,
  Image,
  Text,
  VStack
} from "@chakra-ui/react";
import moment from "moment-timezone";

import Card from "components/Card";
import book from "images/icons/book.svg";
import newspaper from "images/icons/newspaper.svg";

const Project = ({ project }) => (
  <Card 
    width = "full"
    cursor = "pointer"
    onClick = { () => window.location.assign("/projects/" + project.slug + "/dashboard") }
  >
    <HStack spacing = "4">
      <Image 
        src = { project.cover_art_url || ( project.type === "book" ? book : newspaper ) } 
        alt = "Project cover art" 
        height = "75px"
      />
      <VStack 
        spacing = "2"
        alignItems = "flex-start"
      >
        <Heading fontSize = "xl" fontWeight = "medium">{ project.title }</Heading>
        <HStack spacing = "8">
          <Text>Followers: {project.followers_count.aggregate.count}</Text>
          <Text>Started at: {moment(project.created_at).format("LL")}</Text>
        </HStack>
      </VStack>
    </HStack>
  </Card>
);

const ProjectsList = ({ projects }) => (
  <VStack>
    { projects.map(project => <Project project = { project } key = { project.id } /> )}
  </VStack>
);

export default ProjectsList;