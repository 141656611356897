import { Image } from '@chakra-ui/react';

import logo from "images/zine-logo.png";

const Logo = ({ ...props }) => {

  return (
    <Image alt = "Zine Logo" src = { logo } { ...props } />
  )
};

export default Logo;