import { useEffect } from "react";
import {
  Avatar,
  Box,
  Heading,
  HStack,
  Link,
  Text,
  VStack
} from "@chakra-ui/react";

import Comments from "./Comments";
import Page from "components/Page";
import ProjectCoverArt from "components/ProjectCoverArt";
import ProjectPostComponent from "components/ProjectPost";

const ProjectPost = ({ post, refetchData }) => {
  useEffect(() => {
    window.analytics.page("Project Post", {
      post_visibility: post.project.post_visibility
    });
    // eslint-disable-next-line
  }, []);

  const projectDashboardUrl = `/projects/${post.project.slug}/dashboard`;

  return (
    <Page maxW = "5xl">
        <Box pb = "2">
          <Box my = "4" >
            <Link 
              fontWeight = "medium" 
              fontSize = "xl"
              href = {`/projects/${post.project.slug}`}
            >
              <HStack>
                <ProjectCoverArt height = "50px" src = { post.project.cover_art_url } projectType = { post.project.type } />
                <Heading mb = "8" fontWeight = "medium">{ post.project.title }</Heading>
              </HStack>
            </Link>
            <HStack mt = "2">
                <Avatar 
                  name = { post.project.author.display_name } 
                  src = { post.project.author.avatar_url } 
                  size = "md"
                />
                <Text>{ post.project.author.display_name }</Text>
              </HStack>
          </Box>
          <VStack spacing = "2">
            <ProjectPostComponent 
              post = { post }
              refetchData = { refetchData }
              onDelete = { () => window.location.assign(projectDashboardUrl) }
            />
            <Comments 
              refetchData = { refetchData }
              post = { post }
            />
          </VStack>
        </Box>
    </Page>
  )
};

export default ProjectPost;