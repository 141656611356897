import { Box, Button, VisuallyHidden } from "@chakra-ui/react";
import { FaGoogle } from 'react-icons/fa';

const GoogleAuthButton = ({ ...props }) => (
  <Button
    variant = "outline"
    leftIcon = { <Box as = { FaGoogle } color = "red.500" /> }
    onClick = { () => window.location.assign(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/providers/google`) }
    { ...props }
  >
    <VisuallyHidden>Authenticate with Google</VisuallyHidden>
    { props.children }
  </Button>
);

export default GoogleAuthButton;


