const parseAuthError = error => {
  const { data } = error.response;

  if ( data.message === "Account already exists." ) {
    return { field: "email", message: "Account already exists", code: "Account already exists" }
  } else if ( data.error === "Bad Request" && !data.message ) {
    return { field: "email", message: "Please log in with Google", code: "Google auth required" }
  } else if ( data.message === "Account does not exist." ) {
    return { field: "email", message: "Account does not exist", code: "Account does not exist" }
  } else if ( data.message === "Username and password do not match" || data.message === "Incorrect current password." ) {
    return ({ field: "current-password", message: "Incorrect password", code: "Incorrect password" })
  } else {
    console.log(error.response);
    return ({})
  }
};

export default parseAuthError;
