const Heading = {
  variants: {
    h1: props => ({
      as: "h1",
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: "semibold",
    }),
    h2: props => ({
      fontSize: "1.25rem",
      lineHeight: "1.75rem",
      fontWeight: "medium",
      as: "h2"
    }),
    h3: props => ({
      as: "h3",
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      fontWeight: "medium"
    })
  }
};

export default Heading;