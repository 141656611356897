import {
  Box,
  Text,
  useColorModeValue as mode
} from "@chakra-ui/react";

const FieldGroup = ({ title, description, ...props }) => (
  <Box width = "full">
    <Text
      fontWeight = "semibold"
    >
      { title }
    </Text>
    { description && (
      <Text
        color = { mode("gray.600", "gray.400") }
        fontSize = "md"
      >
        { description }
      </Text>
    )}
    <Box 
      pt = "5" 
      { ...props } 
    />
  </Box>
);

export default FieldGroup;