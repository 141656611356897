import { useState } from "react";
import {
  Box,
  Editable,
  EditableInput,
  EditablePreview
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const UPDATE_SECTION_TITLE = gql`
  mutation UpdateSectionTitle($section_id: uuid!, $title: String!) {
    update_sections_by_pk(
      pk_columns: { id: $section_id }
      _set: { title: $title }
    ) {
      id
      title
      __typename
    }
  }
`;

const SectionName = ({ section }) => {
  const [ title, setTitle ] = useState(section.title);
  const [ updateSectionTitle ] = useMutation(UPDATE_SECTION_TITLE);

  const onUpdateTitle = newValue => {
    if ( newValue !== section.title ) {
      updateSectionTitle({
        variables: {
          section_id: section.id,
          title: newValue
        },
        context: {
          headers: {
            "x-hasura-role": "author"
          }
        }
      })
      .then(() => {
        window.analytics.track("Section Updated", { field: "title" })
      })
    }
  };

  return (
    <Box>
      <Editable
        fontSize = "3xl"
        defaultValue = { section.title }
        onChange = { value => setTitle(value) }
        onSubmit = { onUpdateTitle }
        value = { title }
      >
        <EditablePreview />
        <EditableInput />
      </Editable>
    </Box>
  )
};

export default SectionName;