import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  chakra,
  HStack,
  Input,
  Textarea,
  Text,
  VStack
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import FieldGroup from "components/FieldGroup";

const postTypes = ["Update", "Milestone", "Question"];
const getPostTypePlaceholder = postType => {
  if ( postType === "update" ) {
    return "Give an update on the project."
  } else if ( postType === "milestone" ) {
    return "Share a major project milestone"
  } else if ( postType === "question" ) {
    return "Ask a question and get some feedback"
  }
};

const CREATE_POST = gql`
  mutation CreatePost($new_post: project_posts_insert_input!) {
    insert_project_posts_one(
      object: $new_post
    ) {
      id
    }
  }
`;

const CreatePost = ({ projectId }) => {
  const [ postType, setPostType ] = useState("update");
  const [ title, setTitle ] = useState("");
  const [ content, setContent ] = useState("");
  const [ createPost ] = useMutation(CREATE_POST, {
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  })

  const isValid = content.length > 0 && title.length > 0;

  const onSubmit = e => {
    e.preventDefault();

    createPost({
      variables: {
        new_post: {
          project_id: projectId,
          title,
          content,
          type: postType
        }
      }
    })
    .then(() => {
      window.analytics.track("Project Post Created", {
        type: postType
      });
      setTitle("");
      setContent("");
      setPostType("update");
    })
  }

  return (
    <FieldGroup title = "Create a Post">
      <Container variant = "card" py = "4">
        <chakra.form width = "full" onSubmit = { onSubmit }>
          <VStack spacing = "2" alignItems = "flex-start">
            <HStack mb = "2" width = "full">
              <Text>Post Type</Text>
              <ButtonGroup isAttached size = "sm">
              { postTypes.map(pt => (
                <Button
                  key = { pt }
                  variant = { postType === pt.toLowerCase() ? "solid" : "outline" }
                  onClick = { () => setPostType(pt.toLowerCase()) }
                  colorScheme = "gray"
                  _focus = {{
                    boxShadow: "none"
                  }}
                  { ... postType === pt.toLowerCase() ? { border: "1px", borderColor: "transparent" } : {} }
                >{ pt }</Button>
              ))}
            </ButtonGroup>
            </HStack>
            <Input
              value = { title }
              onChange = { e => setTitle(e.target.value) }
              placeholder = { getPostTypePlaceholder(postType) }
            />
            <Textarea
              value = { content }
              onChange = { e => setContent(e.target.value) }
              placeholder = "You can go into more detail here"
            />
            <HStack justifyContent = "flex-end" width = "full">
              <Button
                isDisabled = { !isValid }
                type = "submit"
                size = "sm"
                width = {{ base: "full", md: "auto" }}
                colorScheme = "primary"
                textTransform = "capitalize"
              >Post {postType}</Button>
              </HStack>
          </VStack>
        </chakra.form>
      </Container>
    </FieldGroup>
  )
};

export default CreatePost;