import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import gql from "graphql-tag";
import { useMutation } from '@apollo/client';

const DELETE_PROJECT_POST = gql`
  mutation DeleteProjectPost($project_post_id: uuid!) {
    delete_project_posts_by_pk(id: $project_post_id) {
      id
    }
  }
`;

const DeletePost = ({ post, onDeleteCallback }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [ deleteProjectPost ] = useMutation(DELETE_PROJECT_POST, {
    context: {
      headers: {
        "x-hasura-role": "author"
      }
    }
  });

  const onDelete = () => {
    deleteProjectPost({
      variables: {
        project_post_id: post.id
      }
    })
    .then(() => {
      window.analytics.track("Project Post Deleted", {
        type: post.type
      });
      onDeleteCallback();
      onClose();
    })
  }

  return (
    <>
      <IconButton 
        aria-label="Delete post" 
        icon = { <DeleteIcon /> } 
        onClick = { onOpen }
        variant = "ghost"
        size = "sm"
      />

      <AlertDialog
        isOpen = { isOpen }
        leastDestructiveRef = { cancelRef }
        onClose = { onClose }
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Post
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This action is irreversible.
            </AlertDialogBody>

            <AlertDialogFooter 
              display = {{ base: "block", md: "flex" }} 
              justifyContent = "space-between"
              experimental_spaceY = "1"
            >
              <Button 
                onClick = { onClose } 
                ref = { cancelRef }
                width = {{ base: "full", md: "auto" }}
              >
                Cancel
              </Button>
              <Button 
                colorScheme = "danger" 
                onClick = { onDelete }
                width = {{ base: "full", md: "auto" }}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeletePost;