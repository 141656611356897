import {
  Box,
  Circle,
  Container,
  Heading,
  HStack,
  Image,
  Stack,
  StackDivider,
  useColorModeValue as mode
} from "@chakra-ui/react";

import people from "images/icons/people.svg";
import writePaper from "images/icons/write-paper.svg";

const StatCard = ({ icon, label, value }) => (
  <Stack mx = "auto" spacing = "3">
    <Box color = { mode('gray.600', 'gray.400' )} fontWeight = "medium">{ label }</Box>
    <HStack spacing = "3">
      <Circle flexShrink = { 0 } size = "6" shadow = { mode("light.sm", "dark.sm") }>
        <Image src = { icon } />
      </Circle>
      <Heading variant = "h2" fontWeight = "bold" size = "xl">{ value }</Heading>
    </HStack>
  </Stack>
)

const Stats = ({ followersCount, sectionsCount, sectionLabel }) => (
  <Container 
    variant = "card"
    py = "2"
  >
    <Stack
      spacing = "8"
      justifyContent = "space-between"
      direction = {{ base: 'column', md: 'row' }}
      divider = { <StackDivider /> }
    >
      <StatCard
        value = { followersCount }
        label = "Followers"
        icon = { people }
      />

      <StatCard
        value = { sectionsCount }
        label = { sectionLabel }
        icon = { writePaper }
      />
    </Stack>
  </Container>
);

export default Stats;