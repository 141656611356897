import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  chakra,
  HStack,
  Image,
  Input,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  SimpleGrid,
  StackDivider,
  Textarea,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import Select from "react-select";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

import FieldGroup from "components/FieldGroup";
import { TagsSelect } from "components/Select";
import book from "images/icons/book.svg";
import newspaper from "images/icons/newspaper.svg";
import { storage } from "utils/nhost";

const CREATE_PROJECT = gql`
  mutation CreateProject($project: projects_insert_input!) {
    new_project: insert_projects_one(
      object: $project
    ) {
      id
    }
  }
`

const UPDATE_COVER_ART = gql`
  mutation UpdateCoverArt($project_id: uuid!, $cover_art_url: String!) {
    update_projects_by_pk(
      pk_columns: { id: $project_id }
      _set: { cover_art_url: $cover_art_url }
    ) {
      id
    }
  }
`;

const ProjectForm = () => {
  const [ title, setTitle ] = useState("");
  const [ tagline, setTagline ] = useState("");
  const [ summary, setSummary ] = useState("");
  const [ projectType, setProjectType ] = useState("book");
  const [ profileVisibility, setProfileVisibility ] = useState("public");
  const [ postsVisibility, setPostsVisibility ] = useState("public");
  const [ tags, setTags ] = useState([]);
  const [ slug, setSlug ] = useState("");
  const [ coverArtFile, setCoverArtFile ] = useState(null);
  const [ createProject ] = useMutation(CREATE_PROJECT);
  const [ isSaving, { toggle: onToggleIsSaving }] = useBoolean(false);
  const [ updateCoverArt ] = useMutation(UPDATE_COVER_ART);
  const [ error, setError ] = useState({});

  const coverArtRef = useRef(null);
  const uploadButtonRef = useRef(null);

  useEffect(() => {
    setSlug(convertToSlug(title));
  }, [ title ]);

  const onSubmit = e => {
    e.preventDefault();
    onToggleIsSaving();

    const bannedSlugs = ['new'];
    if ( bannedSlugs.includes(slug) ) {
      setError({ field: "slug", message: "Slug cannot be 'new' "});
      onToggleIsSaving();
      window.analytics.track("Error Triggered", {
        error: "slug cannot be 'new'"
      })
      return true;
    }

    createProject({
      variables: {
        project: {
          title,
          slug,
          summary,
          tagline,
          type: projectType,
          profile_visibility: profileVisibility,
          post_visibility: postsVisibility,
          project_tags: {
            data: tags.map(tag => ({ tag_id: tag.value }))
          }
        }
      },
      context: {
        headers: {
          "x-hasura-role": "author"
        }
      }
    })
    .then(async resp => {
      if ( coverArtFile ) {
        const projectId = resp.data.new_project.id;
        const path = `/public/projects/${projectId}/${coverArtFile.name}`;

        await storage.put(path, coverArtFile, null, data => {
          if ( data.loaded === data.total ) {
            const coverArtUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}/storage/o${path}`;
            updateCoverArt({
              variables: {
                project_id: projectId,
                cover_art_url: coverArtUrl
              },
              context: {
                headers: {
                  "x-hasura-role": "author"
                }
              }
            })
            .then(() => {
              window.analytics.track("Project Created", {
                type: projectType,
                profile_visibility: profileVisibility,
                post_visibility: postsVisibility,
                has_cover_art: !!coverArtFile
              });
              window.location.assign('/projects/' + slug )
            })
          }
        })
      } else {
        window.location.assign('/projects/' + slug )
      }
    })
    .catch(err => {
      onToggleIsSaving();
      if ( err.message.includes('projects_slug_key') ) {
        setError({ field: 'slug', message: "This slug is already taken" })
      }
      window.analytics.track("Error Triggered", {
        error: "slug taken"
      })
    })
  }

  const convertToSlug = string => string.toLowerCase().replace(/ +/g,"-").replace(/[[\]@'.,/#!$%^&*\\;:{}=_`~()]/g, "");

  const projectTypeOptions = [
    { label: "Book", value: "book" },
    { label: "Newsletter", value: "newsletter" }
  ];
  const projectTypeValue = projectTypeOptions.find(option => option.value === projectType);

  const visibilityOptions = [
    { label: "Public (anyone can view)", value: "public" },
    { label: "Limited (only followers can view)", value: "limited" },
    { label: "Private (only you can view)", value: "private" }
  ];
  const profileVisibilityValue = visibilityOptions.find(option => option.value === profileVisibility);
  const postsVisibilityValue = visibilityOptions.find(option => option.value === postsVisibility);

  const isValid = title.length > 0 && slug.length > 0 && tagline.length > 0 && summary.length > 0;

  const handleUploadButtonClick = () => uploadButtonRef.current.click();
  const onCovertArtChange = async e => {
    const file = e.target.files[0];
    setCoverArtFile(file);

    if ( file ) {
      var reader = new FileReader();
      reader.onload = function(event) {
        coverArtRef.current.src = event.target.result;
      };
  
      reader.readAsDataURL(file);
    }
  }

  return (
    <chakra.form onSubmit = { onSubmit }>
      <VStack
        divider = { <StackDivider /> }
        spacing = "3"
        width = "full"
      >
        <FieldGroup title = "Project Details">
          <SimpleGrid 
            columns = {{ base: 1, md: 2 }}
            alignItems = "center"
          >
            <Box height = "375px">
              <VStack
                mx = "4"
                my = "2"
                px = "4"
                spacing = "4"
                height = "full"
              >
                <Box display = "flex" width = "full" height = "300px" justifyContent = "center">
                  <Image
                    ref = { coverArtRef }
                    display = { coverArtFile ? "inline-flex" : "none" }
                    height = "full"
                    alt = "Project cover art"
                  />
                  <Image
                    src = { projectType === "book" ? book : newspaper }
                    alt = "Default project image"
                    height = "full"
                    display = { coverArtFile ? "none" : "inline-flex" }
                  />
                </Box>
                <Box display = "flex" flexGrow = "0">
                  <HStack my = "4">
                    <Button
                      variant = "ghost"
                      size = "sm"
                      onClick = { handleUploadButtonClick }
                    >{ coverArtFile ? "Change Cover Art" : "Upload Cover Art" }</Button>
                    <Button
                      variant = "ghost"
                      size = "sm"
                      onClick = { () => setCoverArtFile(null) }
                      isDisabled = { !coverArtFile }
                    >Remove Cover Art</Button>
                  </HStack>
                  <Input
                    type = "file"
                    onChange = { onCovertArtChange }
                    display = "none"
                    ref = { uploadButtonRef }
                    accept="image/png, image/gif, image/jpeg"
                  />
                </Box>
              </VStack>
            </Box>
            <VStack>
              <FormControl>
                <FormLabel>Title</FormLabel>
                <Input
                  value = { title }
                  onChange = { e => setTitle(e.target.value) }
                  placeholder = "My Awesome Project"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Tagline</FormLabel>
                <Input
                  value = { tagline }
                  onChange = { e => { if ( e.target.value.length <=60 ) { setTagline(e.target.value) }} }
                />
                <FormHelperText textAlign = "right">Required. {tagline.length}/60 characters</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Summary</FormLabel>
                <Textarea
                  noOfLines = "3"
                  resize = "none"
                  value = { summary }
                  onChange = { e => setSummary(e.target.value) }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select
                  options = { projectTypeOptions }
                  value = { projectTypeValue }
                  onChange = { item => setProjectType(item.value) }
                />
              </FormControl>

              <FormControl isInvalid = { error.field === "slug"}>
                <FormLabel>Slug</FormLabel>
                <Input
                  value = { slug }
                  onChange = { e => setSlug(convertToSlug(e.target.value)) }
                />
                <FormHelperText>This will be used in your project's url.</FormHelperText>
                <FormErrorMessage>{ error.message } </FormErrorMessage>
              </FormControl>
            </VStack>
          </SimpleGrid>
        </FieldGroup>

        <FieldGroup title = "Settings">
          <SimpleGrid
            columns = {{ base: 1, md: 2 }}
            spacingY = {{ base: 0, md: 8 }}
          >
              <FormLabel>Project Profile Visibility</FormLabel>
              <Select
                options = { visibilityOptions }
                value = { profileVisibilityValue }
                onChange = { item => setProfileVisibility(item.value) }
              />

              <FormLabel mt = {{ base: 4, md: 0 }}>Project Posts Visibility</FormLabel>
              <Select
                options = { visibilityOptions }
                value = { postsVisibilityValue }
                onChange = { item => setPostsVisibility(item.value) }
              />
            </SimpleGrid>
        </FieldGroup>

        <FieldGroup 
          title = "Tags"
          description = { `Pick tags that best represent your ${projectType}.` }
        >
          <FormControl>
            <TagsSelect
              value = { tags }
              onChange = { setTags }
              limit = { 5 }
            />
            <FormHelperText>Limit 5</FormHelperText>
          </FormControl>
        </FieldGroup>
      </VStack>
      <Box
        width = "full"
        display = "flex"
        justifyContent = "flex-end"
        mt = "4"
      >
        <Button
          width = {{ base: "full", sm: "unset" }}
          colorScheme = "primary"
          isDisabled = { !isValid }
          isLoading = { isSaving }
          loadingText = "Creating Project..."
          type = "submit"
        >Create Project</Button>
      </Box>
    </chakra.form>
  )
};

export default ProjectForm;