class Segment {
  track = (event, properties = {} ) => {
    return new Promise((resolve, reject) => {
      try {
        window.analytics.track(event, properties, resolve);
      } catch (e) {
        reject();
      }
    })
  };

  identify = (userId, properties = {}) => {
    return new Promise((resolve, reject) => {
      try {
        window.analytics.identify(userId, properties, resolve);
      } catch (e) {
        reject();
      }
    })
  };

  alias = userId => {
    return new Promise((resolve, reject) => {
      try {
        window.analytics.alias(userId, resolve);
      } catch (e) {
        reject();
      }
    })
  }
};

export default Segment;