import { useEffect, useMemo } from "react";
import {
  Box,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import { useAuth } from '@nhost/react-auth';

import LoginForm from './LoginForm';
import Card from "components/Card";
import DividerWithText from "components/DividerWithText";
import GoogleAuthButton from 'components/GoogleAuthButton';
import Logo from "components/Logo";
import Page from "components/Page";

const Login = () => {
  const { signedIn } = useAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isLoggingIn = useMemo(() => !signedIn, []);

  useEffect(() => {
    if ( signedIn ) {
      if (!isLoggingIn ) {
        window.location.assign("/");
      }

      // Otherwise, don't redirect user so that analytics call can finish
    } else {
      window.analytics.page("Log In");
    }
  }, [ signedIn, isLoggingIn ]);

  return (
    <Page maxW = "unset" p = "0">
      <Box
        py="12"
        px={{
          base: '4',
          lg: '8',
        }}
      >
        <Box maxW="md" mx="auto">
          <Logo
            mx="auto"
            h="24"
            mb={{
              base: '5',
              md: '10',
            }}
          />
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            Log in to your account
          </Heading>
          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">Don&apos;t have an account?</Text>
            <Link ml = "1" color = "primary.500" href="/signup">Sign up</Link>
          </Text>
          <Card>
            <LoginForm />
            <DividerWithText mt="6">or</DividerWithText>
            <Box mt="6" columns={3} spacing="3">
              <GoogleAuthButton width = "full">
                Log in with Google
              </GoogleAuthButton>
            </Box>
          </Card>
        </Box>
      </Box>
    </Page>
  );
}

export default Login;