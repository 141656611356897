import {
  Button,
  Image,
  Text,
  useColorModeValue as mode,
  VStack
} from "@chakra-ui/react";

import quil from "images/icons/quil.svg";

const NoProjects = () => {
  return (
    <VStack 
      spacing = "4" 
      bg = { mode('white', 'gray.700') }
      shadow = { mode('light.sm', 'dark.sm') }
      py = {{ base: 5, md: 10 }}
      px = {{ base: 4, md: 10 }}
      rounded = "md"
    >
      <Image 
        src = { quil } 
        alt = "Quil" 
      />
      <Text>Let's get started with your first project</Text>
      <Button 
        colorScheme = "primary"
        width = {{ base: "full", sm: "unset" }}
        onClick = { () => window.location.assign("/projects/new") }
      >Create My First Project</Button>
    </VStack>
  )
};

export default NoProjects;