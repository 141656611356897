import { useState } from "react";
import {
  Box,
  Button,
  chakra,
  FormControl,
  FormLabel,
  Textarea
} from "@chakra-ui/react";
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';

import UserAvatar from "components/UserAvatar";
import { auth } from "utils/nhost";

const GET_USER = gql`
  query GetUser($user_id: uuid!) {
    user: users_by_pk(id: $user_id) {
      id
      avatar_url
      display_name
      __typename
    }
  }
`;

const ADD_COMMENT = gql`
  mutation AddComment($content: String!, $project_post_id: uuid!) {
    new_comment: insert_project_post_comments_one(object: {
      project_post_id: $project_post_id,
      content: $content
    }) {
      id
    }
  }
`;

const AddComment = ({ postId, onCreateComment }) => {
  const [ content, setContent ] = useState("");
  const { data: userData } = useQuery(GET_USER, {
    variables: {
      user_id: auth.user()?.id
    }
  });
  const user = userData?.user;
  const [ addComment ] = useMutation(ADD_COMMENT);

  const onSubmit = e => {
    e.preventDefault();

    addComment({
      variables: {
        content,
        project_post_id: postId
      }
    })
    .then(() => {
      onCreateComment();
      setContent("");
      window.analytics.track("Project Post Comment Created");
    })
  }

  return (
    <Box width = "full">
      <Box display = "flex" experimental_spaceX = "3">
        <Box flexShrink = "0">
          <UserAvatar name = { user?.display_name } src = { user?.avatar_url } size = "md"/>
        </Box>
        <Box
          minW = "0"
          flex = "1"
        >
          <chakra.form onSubmit = { onSubmit }>
            <FormControl>
              <FormLabel srOnly>Comment</FormLabel>
              <Textarea 
                value = { content }
                onChange = { e => setContent(e.target.value) }
                placeholder = "What do you think?"
                resize = "vertical"
                width = "full"
                rounded = "md"
              />
            </FormControl>
            <Box display = "flex" justifyContent = "flex-end" mt = "3">
              <Button
                type = "submit"
                colorScheme = "primary"
                size = "sm"
                width = {{ base: "full", sm: "unset" }}
              >Post Comment</Button>
            </Box>
          </chakra.form>
        </Box>
      </Box>
    </Box>
  )
};

export default AddComment;